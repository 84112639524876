import { Column } from '@tanstack/react-table';
import { Dropdown } from 'cfa-react-components';
import { useMemo } from 'react';
import { StaffPerson } from '../../../interfaces/StaffPerson';

export function Filter({
  column,
  name,
}: {
  column: Column<StaffPerson>;
  name: string;
}) {
  const facetedUniqueValues = column.getFacetedUniqueValues();

  const sortedUniqueValues = useMemo(
    () =>
      Array.from(facetedUniqueValues.keys())
        .sort()
        .filter((k) => typeof k !== 'undefined'),
    [facetedUniqueValues]
  );

  if (sortedUniqueValues.length === 0 || sortedUniqueValues.length > 50) {
    return null;
  }

  return (
    <Dropdown
      label={name}
      value={(column.getFilterValue() as string) ?? ''}
      onChange={(newValue) =>
        column.setFilterValue(newValue === 'All' ? undefined : newValue)
      }
      placeholder={`Search... (${sortedUniqueValues.length})`}
      options={['All', ...sortedUniqueValues]}
      renderOption={(option: any) => (
        <div className="py-2 text-sm leading-snug">{option}</div>
      )}
      compact
    />
  );
}
