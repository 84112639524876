import { IconMaximize, IconMinimize } from '@tabler/icons-react';
import L from 'leaflet';
import 'leaflet.fullscreen';
import { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useMap } from 'react-leaflet';

export function FullscreenToggle(props: FullscreenOptions): null {
  const map = useMap();
  const ctrl = useRef(
    L.control.fullscreen({
      ...props,
      content: ReactDOMServer.renderToString(
        <div className="flex items-center justify-center w-full h-full text-secondary">
          <IconMaximize className="w-4 h-4 leaflet-fullscreen-on:hidden" />
          <IconMinimize className="hidden w-4 h-4 leaflet-fullscreen-on:block" />
        </div>
      ),
    })
  );

  useEffect(() => {
    const current = ctrl.current;
    current.addTo(map);

    return () => {
      current.remove();
    };
  });

  return null;
}
