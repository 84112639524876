import { IconCheck, IconLink } from '@tabler/icons-react';
import { IconButton, Tooltip } from 'cfa-react-components';
import { useState } from 'react';
import { copyTextToClipboard } from '../../lib/utils';

export function DataTableLink({
  generateLink,
  loading,
}: {
  generateLink: any;
  loading: boolean;
}) {
  const [copied, setCopied] = useState(false);

  return (
    <Tooltip content={copied ? 'Link Copied!' : 'Generate and Copy Link'}>
      <IconButton
        onClick={() => {
          setCopied(false);
          generateLink();
          copyTextToClipboard(window.location.href).then(() => setCopied(true));
          setTimeout(function () {
            setCopied(false);
          }, 5000);
        }}
        disabled={loading}
      >
        {copied ? (
          <IconCheck aria-label="Link copied" className="text-teal" />
        ) : (
          <IconLink />
        )}
      </IconButton>
    </Tooltip>
  );
}
