const oktaAuthConfig = {
  // Note: If your app is configured to use the Implicit flow
  // instead of the Authorization Code with Proof of Code Key Exchange (PKCE)
  // you will need to add `pkce: false`
  issuer: process.env.REACT_APP_OKTA_ISSUER ?? '',
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid', 'email', 'profile', 'dtt-role-model-api:read'],
};

const oktaSignInConfig = {
  baseUrl: process.env.REACT_APP_OKTA_BASE_URL,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.location.origin}/implicit/callback`,
  authParams: {
    issuer: process.env.REACT_APP_OKTA_ISSUER,
  },
  // Additional documentation on config options can be found at https://github.com/okta/okta-signin-widget#basic-config-options
};

export { oktaAuthConfig, oktaSignInConfig };
