import {
  ColumnDef,
  FilterFn,
  Row,
  createColumnHelper,
} from '@tanstack/react-table';
import { StaffPerson } from '../../interfaces/StaffPerson';
import { camelCaseToTitle } from '../../lib/utils';

const columnHelper = createColumnHelper<StaffPerson>();

const wordBoundaryFilterFn: FilterFn<any> = (
  row: Row<any>,
  columnId: string,
  filterValue: any,
  addMeta: (meta: any) => void
): boolean => {
  if (filterValue === undefined || filterValue.length === 0) {
    return false;
  } else {
    filterValue = filterValue.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
    //const regex = new RegExp(`(\\b|^)${filterValue}(\\b|$)`, 'i');
    const regex = new RegExp(`${filterValue}`, 'i');
    return regex.test(row.original[columnId]);
  }
};

function stripLeading(input: string, search: string): string {
  input = input.replace('sensitive_', '');
  input = input.replace('address_', '');
  const lastOccurrenceIndex = input.lastIndexOf(search);

  if (lastOccurrenceIndex !== -1) {
    const prefix = input.slice(0, lastOccurrenceIndex);
    const suffix = input.slice(lastOccurrenceIndex + 1);
    if (suffix.toLowerCase().includes(prefix.toLowerCase())) {
      return suffix;
    }
    return `${prefix}${suffix.charAt(0).toUpperCase()}${suffix.slice(1)}`;
  }

  return input.replace('_', ' ');
}

export function formatLabel(label: string): string {
  return camelCaseToTitle(stripLeading(label, '_').replace('_', ' '));
}

export function generateColumns(
  attributes: string[]
): ColumnDef<StaffPerson>[] {
  const columns: ColumnDef<StaffPerson>[] = [];

  columns.push(
    columnHelper.display({
      id: 'actions',
      meta: { label: 'Actions' },
    })
  );

  attributes.forEach((s) => {
    columns.push(
      columnHelper.accessor(s as any, {
        id: s,
        meta: { label: formatLabel(s) },
        filterFn: wordBoundaryFilterFn,
        header: formatLabel(s),
      })
    );
  });

  return columns;
}
