import {
  IconDownload,
  IconEyeDown,
  IconWorldDownload,
} from '@tabler/icons-react';
import { Column, Row, Table } from '@tanstack/react-table';
import {
  Divider,
  IconButton,
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuItem,
  PopoverMenuToggleButton,
  Tooltip,
  Typography,
} from 'cfa-react-components';
import { StaffPerson } from '../../interfaces/StaffPerson';
import { downloadCsv } from '../../lib/utils';

export function DataTableExport({
  table,
  loading,
}: {
  table: Table<StaffPerson>;
  loading: boolean;
}) {
  function exportAllColumns(
    data: Row<StaffPerson>[],
    columns: Column<StaffPerson>[]
  ): void {
    // remove "actions" column
    columns = columns.filter((c) => c.id !== 'actions');

    // obtain column headers
    const headers = columns.map((c) => c.id);

    // obtain row values
    const rows = data.map(({ original: row }) => {
      const values = columns.map((c) => {
        let value = (row as any)[c.id];
        if (typeof value === 'undefined') {
          return '';
        }

        value = String(value);
        return `"${value}"`;
      });

      return values.join(',');
    });

    // combine headers and row values
    const csvContents = headers.join(',') + '\n' + rows.join('\n');
    downloadCsv(csvContents, 'staff-export');
  }

  return (
    <PopoverMenu placement="bottom-end">
      <Tooltip content="Download Data">
        <PopoverMenuToggleButton as={IconButton} disabled={loading}>
          <IconDownload />
        </PopoverMenuToggleButton>
      </Tooltip>

      <PopoverMenuContent>
        <Typography variant="overline3" className="mx-4 mt-2">
          Visible Columns
        </Typography>

        <PopoverMenuItem
          className="gap-2"
          onClick={() => {
            exportAllColumns(
              table.getFilteredSelectedRowModel().flatRows,
              table
                .getAllFlatColumns()
                .filter((column) => column.getIsVisible())
            );
          }}
          disabled={table.getFilteredSelectedRowModel().flatRows.length === 0}
        >
          <IconEyeDown />
          Export {
            table.getFilteredSelectedRowModel().flatRows.length
          } Selected{' '}
          {table.getFilteredSelectedRowModel().flatRows.length === 1
            ? 'Row'
            : 'Rows'}
        </PopoverMenuItem>
        <PopoverMenuItem
          className="gap-2"
          onClick={() => {
            exportAllColumns(
              table.getFilteredRowModel().flatRows,
              table
                .getAllFlatColumns()
                .filter((column) => column.getIsVisible())
            );
          }}
        >
          <IconEyeDown />
          Export All Rows
        </PopoverMenuItem>

        <Divider />

        <Typography variant="overline3" className="mx-4 mt-2">
          All Columns
        </Typography>

        <PopoverMenuItem
          className="gap-2"
          onClick={() => {
            exportAllColumns(
              table.getFilteredSelectedRowModel().flatRows,
              table.getAllFlatColumns()
            );
          }}
          disabled={table.getFilteredSelectedRowModel().flatRows.length === 0}
        >
          <IconWorldDownload />
          Export {
            table.getFilteredSelectedRowModel().flatRows.length
          } Selected{' '}
          {table.getFilteredSelectedRowModel().flatRows.length === 1
            ? 'Row'
            : 'Rows'}
        </PopoverMenuItem>
        <PopoverMenuItem
          className="gap-2"
          onClick={() => {
            exportAllColumns(
              table.getFilteredRowModel().flatRows,
              table.getAllFlatColumns()
            );
          }}
        >
          <IconWorldDownload />
          Export All Rows
        </PopoverMenuItem>
      </PopoverMenuContent>
    </PopoverMenu>
  );
}
