import { request, getOktaAccessToken } from './api';
import { StaffResponse } from '../../interfaces/StaffResponse';

const getStaff = (
  offset: number = 0,
  limit: number = 1000
): Promise<StaffResponse> => {
  const token = getOktaAccessToken();
  if (!token) {
    return Promise.reject('Missing Token');
  }

  return (
    request({
      method: 'GET',
      url: `/staff?offset=${offset}&limit=${limit}`,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      // it's recommended to interface response types and object types
      .then((response) => {
        return response;
      })
      .catch((error) => {
        error = 'Could not retrieve staff';
        throw error;
      })
  );
};

export { getStaff };
