// reference: https://github.com/okta/okta-react/tree/master/samples/routing/react-router-dom-v6
// related issue: https://github.com/okta/okta-react/issues/178

import { toRelativeUrl } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import PageLoader from '../PageLoader';

export const RequiredAuth: React.FC = () => {
  const { oktaAuth, authState } = useOktaAuth();

  useEffect(() => {
    if (!authState) {
      return;
    }

    if (!authState?.isAuthenticated) {
      const originalUri = toRelativeUrl(
        window.location.href,
        window.location.origin
      );
      oktaAuth.setOriginalUri(originalUri);
      oktaAuth.signInWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oktaAuth, !!authState, authState?.isAuthenticated]);

  if (!authState || !authState?.isAuthenticated) {
    return <PageLoader />;
  }

  return <Outlet />;
};
