import { IconCheck, IconCopy } from '@tabler/icons-react';
import { Tooltip } from 'cfa-react-components';
import { useState } from 'react';

import { StaffPerson } from '../../interfaces/StaffPerson';
import { copyTextToClipboard } from '../../lib/utils';
import { formatLabel } from '../StaffDataTable/columns';

export function StaffAttributesTable({
  person,
  attributes,
}: {
  person: StaffPerson;
  attributes: string[];
}) {
  const [copied, setCopied] = useState<string>();

  const copyValue = (attribute: string, value?: string) => {
    if (!value) return;

    copyTextToClipboard(String(person[attribute as keyof StaffPerson])).then(
      () => setCopied(attribute)
    );

    setTimeout(function () {
      setCopied(undefined);
    }, 5000);
  };

  return (
    <div className="-mx-2">
      <table className="w-full mx-auto border-separate md:table-fixed">
        <tbody className="flex flex-col gap-2 md:table-row-group">
          {attributes.map((attribute) => (
            <tr
              key={attribute}
              className="flex flex-col transition-colors hover:bg-gray-100/75 group md:table-row"
            >
              <th className="px-2 font-bold w-96">{formatLabel(attribute)}</th>
              <td className="px-2 overflow-auto">
                <div className="flex flex-row items-center justify-between">
                  {person[attribute as keyof StaffPerson]}

                  <Tooltip content="Copy Value">
                    <button
                      onClick={() =>
                        copyValue(
                          attribute,
                          person[attribute as keyof StaffPerson]
                        )
                      }
                      className="transition-opacity opacity-0 group-hover:opacity-100 text-secondary"
                    >
                      {copied === attribute ? (
                        <IconCheck
                          aria-label="Link copied"
                          className="w-5 h-5 text-teal"
                        />
                      ) : (
                        <IconCopy className="w-5 h-5" />
                      )}
                    </button>
                  </Tooltip>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
