import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

/**
 * This file sets up the base request stub for all API requests
 * see CfaApi.ts for how to access okta-protected CFA APIs
 * see PublicApi.ts for how to hit public APIs.
 */

// changing the baseURL is helpful when testing a api locally
const base = axios.create({
  baseURL: process.env.REACT_APP_STAFF_EXPLORER_API_BASE,
  headers: {
    'Content-Type': 'application/json',
  },
});

interface ErrorResponse {
  error: string;
  message: string;
  data?: any;
  path?: string;
  status?: number;
  timestamp?: string;
}

const request = (config: AxiosRequestConfig) => {
  const onSuccess = (response: AxiosResponse) => {
    return response.data;
  };

  const onError = (error: AxiosError) => {
    let response: ErrorResponse = {
      error: '',
      message: '',
      status: error.response?.status,
      timestamp: new Date().toLocaleDateString(),
      path: '',
    };

    if (error.response && error.response.data) {
      response.data = error.response.data;
    }

    return Promise.reject(response);
  };

  return base(config).then(onSuccess).catch(onError);
};

const getOktaAccessToken = (): string => {
  const oktaCookie: string | null = localStorage.getItem('okta-token-storage');
  let accessToken: string = '';

  if (oktaCookie) {
    const token = JSON.parse(oktaCookie);
    accessToken = token.accessToken.accessToken;
  }

  return accessToken;
};

export { request, getOktaAccessToken };
