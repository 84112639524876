import { IconInfoCircle, IconUser } from '@tabler/icons-react';
import { Row, Table } from '@tanstack/react-table';
import { Button, Checkbox, Tooltip } from 'cfa-react-components';
import { useState } from 'react';

import { StaffPerson } from '../../interfaces/StaffPerson';
import { StaffDetailModal } from '../StaffDetailModal';

interface DataTableRowActionsProps {
  row: Row<StaffPerson>;
  table: Table<StaffPerson>;
}

export function DataTableRowActions({ row, table }: DataTableRowActionsProps) {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="flex flex-row items-center">
        <Checkbox
          checked={row.getIsSelected()}
          onChange={() => row.toggleSelected(!row.getIsSelected())}
          crossOrigin={undefined}
          onPointerEnterCapture={undefined}
          onPointerLeaveCapture={undefined}
          className="mr-2 w-4 h-4 [&>label>div]:w-4 [&>label>div]:h-4 [&>label>div]:border-secondary [&>label>div>svg]:w-3 [&>label>div>svg]:h-3"
        />

        <Tooltip content="Show Details">
          <Button
            size="sm"
            variant="text"
            color="secondary"
            className="flex h-8 w-8 !min-w-0 p-0 data-[state=open]:bg-muted"
            onClick={() => setOpen(true)}
          >
            <IconInfoCircle className="w-4 h-4" />
          </Button>
        </Tooltip>

        <Tooltip content="Find Manager">
          <Button
            size="sm"
            variant="text"
            color="secondary"
            className="flex h-8 w-8 !min-w-0 p-0 data-[state=open]:bg-muted"
            onClick={() => {
              table.resetGlobalFilter();
              table.setColumnFilters([]);

              const column = table
                .getAllFlatColumns()
                .filter((c) => c.id === 'adId');
              if (column.length === 1) {
                column[0].setFilterValue(
                  (row.original as any).manager_managerAdId
                );
              }
            }}
          >
            <IconUser className="w-4 h-4" />
          </Button>
        </Tooltip>
      </div>

      <StaffDetailModal
        show={open}
        setShow={() => setOpen(false)}
        person={row.original}
      />
    </>
  );
}
