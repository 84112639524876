import { IconInfoCircle, IconX } from '@tabler/icons-react';
import { IconButton, Typography } from 'cfa-react-components';
import { useState } from 'react';
import { Marker, Popup, useMap } from 'react-leaflet';

import { StaffPerson } from '../../interfaces/StaffPerson';
import { cn, getInitials } from '../../lib/utils';
import { StaffDetailModal } from '../StaffDetailModal';

export function MapMarker({
  person,
  showDetail = true,
}: {
  person: StaffPerson;
  showDetail?: boolean;
}) {
  const map = useMap();
  const [open, setOpen] = useState(false);

  return (
    <Marker
      position={[
        Number(person.sensitive_latitude),
        Number(person.sensitive_longitude),
      ]}
    >
      <Popup
        autoPan={true}
        className="items-center justify-center w-72"
        closeButton={false}
      >
        <div className="items-center justify-center w-72">
          <div
            className={cn(
              'flex flex-row',
              showDetail ? 'justify-between' : 'justify-end'
            )}
          >
            {showDetail && (
              <IconButton>
                <IconInfoCircle
                  onClick={() => setOpen(true)}
                  className="text-secondary"
                />
              </IconButton>
            )}
            <IconButton
              className="justify-self-end"
              onClick={() => {
                map && map.closePopup();
              }}
            >
              <IconX className="text-secondary" />
            </IconButton>
          </div>

          <div className="z-10 flex justify-center mb-3">
            {person.sensitive_photo_link ? (
              <img
                src={person.sensitive_photo_link}
                className="rounded-full shadow-lg h-[108px] w-[108px] object-none"
                alt="portrait"
              />
            ) : (
              <div className="flex items-center justify-center h-[108px] w-[108px] overflow-hidden bg-secondary rounded-full shadow-lg">
                <span className="text-2xl font-bold text-white">
                  {getInitials(person.preferredName)}
                </span>
              </div>
            )}
          </div>

          <div className="px-4 pb-4 text-center">
            <Typography color="secondary" variant="h4">
              {person.preferredName}
            </Typography>
            <p className="!mt-1">
              {person.sensitive_address_city}, {person.sensitive_address_state}
            </p>
            <p>{person.organization}</p>
            <p>{person.businessTitle}</p>
          </div>
        </div>
      </Popup>

      {showDetail && (
        <StaffDetailModal show={open} setShow={setOpen} person={person} />
      )}
    </Marker>
  );
}
