import { LatLngExpression } from 'leaflet';
import { ReactNode } from 'react';
import { MapContainer, TileLayer } from 'react-leaflet';

import { FullscreenToggle } from './FullscreenToggle';
import { ZoomControls } from './ZoomControls';

export function Map({
  center = [33.6159553, -84.4960021],
  children,
}: {
  center?: LatLngExpression;
  children?: ReactNode;
}) {
  return (
    <MapContainer
      center={center}
      zoom={8}
      className="z-0 w-full h-full rounded-md"
      zoomControl={false}
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <ZoomControls />
      <FullscreenToggle />

      {children}
    </MapContainer>
  );
}
