import { useDraggable, useDroppable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import {
  IconEyeOff,
  IconGripVertical,
  IconSearch,
  IconSettings,
  IconSortAscendingLetters,
  IconSortDescendingLetters,
} from '@tabler/icons-react';
import { Column, Table } from '@tanstack/react-table';
import {
  Divider,
  PopoverMenu,
  PopoverMenuContent,
  PopoverMenuItem,
  PopoverMenuToggleButton,
  TextField,
} from 'cfa-react-components';

import { StaffPerson } from '../../interfaces/StaffPerson';
import { cn } from '../../lib/utils';

interface DataTableColumnHeaderProps
  extends React.HTMLAttributes<HTMLDivElement> {
  id: string;
  column: Column<StaffPerson>;
  table: Table<StaffPerson>;
  children: any;
}

export function DataTableColumnHeader({
  id,
  column,
  table,
  className,
  children,
}: DataTableColumnHeaderProps) {
  const { setNodeRef: setDroppableNodeRef } = useDroppable({
    id: id,
  });
  const {
    attributes,
    listeners,
    setNodeRef: setDraggableNodeRef,
    transform,
  } = useDraggable({
    id: id,
  });
  const style = {
    transform: CSS.Translate.toString(transform),
  };

  if (!column.getCanSort()) {
    return <div className={cn(className)}>{children}</div>;
  }

  const firstValue = table
    .getPreFilteredRowModel()
    .flatRows[0]?.getValue(column.id);

  const columnFilterValue = column.getFilterValue();

  return (
    <div ref={setDroppableNodeRef}>
      <PopoverMenu placement="bottom">
        <div
          ref={setDraggableNodeRef}
          className={cn(
            'inline-flex items-center justify-center px-2 py-1 transition-colors rounded-md whitespace-nowrap ring-offset-background disabled:pointer-events-none disabled:opacity-50',
            className
          )}
          style={style}
        >
          {children}

          <PopoverMenuToggleButton className="p-1 ml-1 rounded-md hover:bg-gray-200">
            {column.getIsSorted() === 'desc' ? (
              <IconSortDescendingLetters className="w-4 h-4" />
            ) : column.getIsSorted() === 'asc' ? (
              <IconSortAscendingLetters className="w-4 h-4" />
            ) : (
              <IconSettings className="w-4 h-4" />
            )}
          </PopoverMenuToggleButton>

          <button
            className="p-1 rounded-md cursor-move hover:bg-gray-200"
            {...listeners}
            {...attributes}
          >
            <IconGripVertical className="w-4 h-4" />
          </button>
        </div>

        <PopoverMenuContent>
          <PopoverMenuItem
            className="gap-2"
            onClick={() => column.toggleSorting(false)}
          >
            <IconSortAscendingLetters />
            Sort Ascending
          </PopoverMenuItem>
          <PopoverMenuItem
            className="gap-2"
            onClick={() => column.toggleSorting(true)}
          >
            <IconSortDescendingLetters />
            Sort Descending
          </PopoverMenuItem>

          <PopoverMenuItem
            className="gap-2"
            onClick={() => column.toggleVisibility(false)}
          >
            <IconEyeOff />
            Hide Column
          </PopoverMenuItem>

          <Divider className="!my-1" />

          {typeof firstValue === 'string' ? (
            <div className="flex flex-row items-center gap-3 px-4 pt-2 pb-4">
              <IconSearch className="text-secondary" />
              <TextField
                value={(columnFilterValue ?? '') as string}
                onChange={(event) => column.setFilterValue(event.target.value)}
                placeholder={`Search... (${
                  column.getFacetedUniqueValues().size
                })`}
                className="[&>div>input]:border-secondary"
                compact
              />
            </div>
          ) : null}
        </PopoverMenuContent>
      </PopoverMenu>
    </div>
  );
}
