import {
  Link,
  MessageBlock,
  MessageBlockActions,
  MessageBlockContent,
} from 'cfa-react-components';

import { useStickyState } from '../../hooks/useStickyState';
import { cn } from '../../lib/utils';

export function DataTablePerformanceInfo({
  className,
}: {
  className?: string;
}) {
  const [dismissed, setDismissed] = useStickyState(
    false,
    'dismissed-performance-note'
  );

  if (dismissed) {
    return null;
  }

  return (
    <MessageBlock
      onClose={() => setDismissed(true)}
      severity="info"
      title="Performance Note"
      className={cn('max-w-full w-full', className)}
    >
      <MessageBlockContent>
        Welcome to Staff Explorer! For better performance when filtering or
        reordering columns, click "<b>Hide Map</b>". You can add it back any
        time.
      </MessageBlockContent>
      <MessageBlockActions>
        <Link
          variant="standalone"
          className="inline-flex items-center gap-1.5"
          onClick={() => setDismissed(true)}
        >
          Dismiss permanently
        </Link>
      </MessageBlockActions>
    </MessageBlock>
  );
}
