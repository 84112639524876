import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { LoginCallback, Security } from '@okta/okta-react';
import { useEffect } from 'react';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
} from 'react-router-dom';

import Footer from './components/common/Footer';
import Header from './components/common/Header';
import { RequiredAuth } from './components/common/SecureRoute';
import PageLoader from './components/common/PageLoader';
import Staff from './containers/Staff';

import { oktaAuthConfig } from './config';

import 'cfa-react-components/dist/styles.min.css';
import './index.css';

const oktaAuth = new OktaAuth(oktaAuthConfig);

function App(): JSX.Element {
  const navigate = useNavigate();

  // use hooks to set the document.title; can be done on page components to change title for those pages
  useEffect(() => {
    document.title = 'Staff Explorer';
  }, []);

  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Header />
      <main className="mb-12">
        <Routes>
          <Route
            path="/implicit/callback"
            element={<LoginCallback loadingElement={<PageLoader />} />}
          />
          <Route path="" element={<RequiredAuth />}>
            <Route path="/" element={<Staff />} />
          </Route>
        </Routes>
      </main>
      <Footer />
    </Security>
  );
}

export default function AppWithRouterAccess(): JSX.Element {
  return (
    <Router>
      <App />
    </Router>
  );
}
