import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react';
import { Table } from '@tanstack/react-table';
import { Dropdown, LoadingIndicator, Tooltip } from 'cfa-react-components';
import { StaffPerson } from '../../interfaces/StaffPerson';
import { cn } from '../../lib/utils';

export function DataTablePagination({
  table,
  loading,
}: {
  table: Table<StaffPerson>;
  loading: boolean;
}) {
  const totalRows = table.getFilteredRowModel().rows.length;

  const minRow =
    table.getState().pagination.pageIndex *
      table.getState().pagination.pageSize +
    1;

  const maxRow = Math.min(
    totalRows,
    table.getState().pagination.pageIndex *
      table.getState().pagination.pageSize +
      table.getState().pagination.pageSize
  );

  const pageSizes = ['10', '20', '30', '40', '50'];

  return (
    <div className="flex items-center gap-8">
      <div className="flex flex-row items-center gap-4 text-sm shrink-0 text-slate-800">
        <span className="whitespace-nowrap">Rows per page:</span>
        <Dropdown
          placeholder="Select..."
          value={String(table.getState().pagination.pageSize)}
          onChange={(newValue) =>
            table.setPageSize(Number(newValue ?? pageSizes[0]))
          }
          options={pageSizes}
          className="w-16"
          compact
        />
      </div>

      <div className="flex flex-row items-center gap-2 shrink-0">
        <span className="inline-flex items-center gap-2 text-sm text-slate-800">
          {minRow}-{maxRow} of {totalRows}
          {loading ? (
            <Tooltip content="Loading additional results...">
              <LoadingIndicator size="sm" variant="inline" />
            </Tooltip>
          ) : null}
        </span>
        <button
          className={cn('ml-2 cursor-pointer p-1 disabled:text-slate-400')}
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          <span className="sr-only">Go to previous page</span>
          <IconArrowLeft className="w-4 h-4" />
        </button>
        <button
          className={cn('cursor-pointer p-1 disabled:text-slate-400')}
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          <span className="sr-only">Go to next page</span>
          <IconArrowRight className="w-4 h-4" />
        </button>
      </div>
    </div>
  );
}
