import { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import { StaffPage } from '../../components/pages/Staff';
import { generateColumns } from '../../components/StaffDataTable/columns';
import { StaffPerson } from '../../interfaces/StaffPerson';
import { getStaff } from '../../lib/api/CfaApi';

function Staff(): JSX.Element {
  const [loading, setLoading] = useState(false);
  const [allLoaded, setAllLoaded] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const [people, setPeople] = useState<StaffPerson[]>([]);
  const [columns, setColumns] = useState<ColumnDef<StaffPerson>[]>([]);

  const [lastRequest, setLastRequest] = useState<[number, number] | null>(null); // offset, limit
  const [nextRequest, setNextRequest] = useState<[number, number] | null>(null); // offset, limit

  function getPeople() {
    if (!loading) {
      setLoading(true);
      setLastRequest([0, 2000]);

      getStaff(0, 2000)
        .then((res) => {
          setPeople(res.staff);

          if (res.meta.pagination.next) {
            setNextRequest([
              res.meta.pagination.next.offset,
              res.meta.pagination.next.limit,
            ]);
          } else {
            setAllLoaded(true);
          }

          setColumns(generateColumns(res.meta.attributes));
          setLoading(false);
        })
        .catch((error) => {
          setErrorMessage(error ?? 'Unable to retrieve staff.');
          setLoading(false);
          setAllLoaded(true);
        });
    }
  }

  useEffect(() => {
    getPeople();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (nextRequest && nextRequest !== lastRequest) {
      getStaff(nextRequest[0], nextRequest[1])
        .then((res) => {
          setPeople((prevPeople) => [...prevPeople, ...res.staff]);

          if (res.meta.pagination.next) {
            setNextRequest([
              res.meta.pagination.next.offset,
              res.meta.pagination.next.limit,
            ]);
          } else {
            setAllLoaded(true);
            setNextRequest(null);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
  }, [nextRequest, lastRequest]);

  return (
    <StaffPage
      columns={columns}
      data={people}
      message={errorMessage}
      loading={loading}
      allLoaded={allLoaded}
    />
  );
}

export default Staff;
