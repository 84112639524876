import { DndContext, DragOverlay, closestCenter } from '@dnd-kit/core';
import {
  IconAlertTriangle,
  IconEye,
  IconEyeOff,
  IconFilterOff,
} from '@tabler/icons-react';
import {
  ColumnDef,
  ColumnFiltersState,
  ColumnOrderState,
  SortingState,
  VisibilityState,
  flexRender,
  getCoreRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { Button, LoadingIndicator, TextField } from 'cfa-react-components';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { StaffPerson } from '../../interfaces/StaffPerson';
import { cn } from '../../lib/utils';
import { StaffMap } from '../StaffMap';
import { Filter } from '../ui/Table/ColumnFilter';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../ui/Table/table';

import {
  DataTableColumnHeader,
  DataTableColumnVisibility,
  DataTableExport,
  DataTableFilterOverview,
  DataTableLink,
  DataTablePagination,
  DataTablePerformanceInfo,
  DataTableRowActions,
  formatLabel,
} from '../StaffDataTable';

interface DataTableProps {
  loading: boolean;
  allLoaded: boolean;
  message?: string;
  columns: ColumnDef<StaffPerson>[];
  data: StaffPerson[];
}

export function StaffPage({
  loading,
  allLoaded,
  message,
  columns,
  data,
}: DataTableProps) {
  let [searchParams, setSearchParams] = useSearchParams();

  const [sorting, setSorting] = useState<SortingState>([]);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [globalFilter, setGlobalFilter] = useState<string>();
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({});
  const [columnOrder, setColumnOrder] = useState<ColumnOrderState>([]);
  const [rowSelection, setRowSelection] = useState({});

  const [showMap, setShowMap] = useState(true);
  const [defaultColumnsApplied, setDefaultColumnsApplied] = useState(false);
  const [dragActiveId, setDragActiveId] = useState<string | null>(null);
  const [dragOverId, setDragOverId] = useState<string | null>(null);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onColumnFiltersChange: setColumnFilters,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    state: {
      sorting,
      columnFilters,
      globalFilter,
      columnVisibility,
      columnOrder,
      rowSelection,
    },
  });

  useEffect(() => {
    if (columns.length === 0) {
      return;
    }

    const defaultColumns = [
      'actions',
      'preferredName',
      'businessTitle',
      'team_name',
      'costCenter',
      'organization',
      'corporateLocation_corporateLocationName',
    ];
    let columnVis: VisibilityState = {};
    columns
      .map((c) => c.id)
      .forEach((c) => {
        if (defaultColumns.includes(String(c))) {
          columnVis[String(c)] = true;
        } else {
          columnVis[String(c)] = false;
        }
      });
    setColumnVisibility(columnVis);
    setColumnOrder(defaultColumns);
    setDefaultColumnsApplied(true);
  }, [columns]);

  useEffect(() => {
    if (loading) {
      return;
    }

    const queryParam = searchParams.get('q');
    if (queryParam && queryParam.length > 0) {
      table.setGlobalFilter(queryParam);
    }

    const sortParam = searchParams.get('sortBy');
    if (sortParam && sortParam.length > 0) {
      const sortValues = sortParam.split('-');
      if (sortValues.length === 2) {
        const column = table
          .getAllColumns()
          .filter((c) => c.id === sortValues[0]);
        if (column.length > 0) {
          column[0].toggleSorting(sortValues[1] === 'desc');
        }
      }
    }

    const filtersParam = searchParams.get('filters');
    if (filtersParam && filtersParam.length > 0) {
      const filters = filtersParam.split(',');

      filters.forEach((val) => {
        const values = val.split('=');
        if (values.length === 2) {
          const column = table
            .getAllColumns()
            .filter((c) => c.id === values[0]);
          if (column.length > 0) {
            column[0].setFilterValue(values[1]);
          }
        }
      });
    }

    const colsParam = searchParams.get('columns');
    if (colsParam && colsParam.length > 0) {
      const cols = colsParam.split(',');

      if (columns.length > 0) {
        table.getAllLeafColumns().forEach((c) => {
          if (cols.includes(c.id)) {
            c.toggleVisibility(true);
          } else {
            c.toggleVisibility(false);
          }
        });
        table.setColumnOrder(cols);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [loading]);

  function getParams() {
    let params: any = {};

    if (globalFilter && globalFilter.length > 0) {
      params['q'] = globalFilter;
    }

    if (columnFilters && columnFilters.length > 0) {
      params['filters'] = columnFilters
        .map(({ id, value }) => `${id}=${value}`)
        .join(',');
    }

    if (sorting && sorting.length > 0) {
      params['sortBy'] = `${sorting[0].id}-${sorting[0].desc ? 'desc' : 'asc'}`;
    }

    if (columns && columns.length > 0) {
      params['columns'] = table
        .getVisibleLeafColumns()
        .map(({ id }) => id)
        .join(',');
    }

    setSearchParams(params);
  }

  return (
    <div className="container py-8 space-y-8">
      <div className="flex flex-col w-full gap-8 md:flex-row">
        <div className="flex flex-col shrink-0 md:w-3/12 lg:w-2/12">
          <div className="md:sticky top-24">
            <div className="space-y-3">
              <TextField
                data-cy="search-bar"
                label="Search"
                placeholder="Engineer..."
                value={globalFilter ?? ''}
                onChange={(event) => table.setGlobalFilter(event.target.value)}
                compact
              />

              {defaultColumnsApplied
                ? table
                    .getVisibleFlatColumns()
                    .filter((c) => c.getCanSort())
                    .map((c) => (
                      <Filter
                        key={c.id}
                        column={c}
                        name={c.columnDef.meta?.label ?? ''}
                      />
                    ))
                : null}
            </div>

            <div className="mt-5">
              <Button
                size="sm"
                variant="destructive"
                className="gap-2 z-1"
                fullWidth
                onClick={() => {
                  table.resetGlobalFilter();
                  table.setColumnFilters([]);
                }}
                disabled={
                  table.getState().globalFilter === undefined &&
                  table.getState().columnFilters.length === 0
                }
              >
                <IconFilterOff size={16} />
                Clear All Filters
              </Button>

              <Button
                size="sm"
                variant="outlined"
                className="gap-2 mt-2 z-1"
                fullWidth
                onClick={() => setShowMap(!showMap)}
              >
                {showMap ? <IconEyeOff size={16} /> : <IconEye size={16} />}
                {showMap ? 'Hide' : 'Show'} Map
              </Button>
            </div>
          </div>
        </div>

        <div className="flex flex-col w-full gap-4 overflow-x-clip">
          <div className="flex flex-col gap-3 px-4 md:flex-row md:justify-between md:items-center md:gap-4">
            <DataTablePagination table={table} loading={!allLoaded} />
            <div>
              <DataTableExport table={table} loading={!allLoaded} />
              <DataTableColumnVisibility table={table} loading={loading} />
              <DataTableLink generateLink={getParams} loading={loading} />
            </div>
          </div>

          <DataTablePerformanceInfo />
          <DataTableFilterOverview globalFilter={globalFilter} table={table} />

          <div className="w-full overflow-hidden border rounded-md">
            <DndContext
              collisionDetection={closestCenter}
              onDragStart={({ active }) => {
                setDragActiveId(active.id as string);
              }}
              onDragOver={({ over }) => {
                setDragOverId((over?.id as string) ?? null);
              }}
              onDragEnd={({ active, over }) => {
                setDragActiveId(null);
                setDragOverId(null);

                const order = table.getVisibleLeafColumns().map(({ id }) => id);

                if (active && over) {
                  const oldIndex = order.indexOf(active.id as string);
                  const newIndex = order.indexOf(over.id as string);

                  if (oldIndex !== newIndex) {
                    // reorder columns
                    const newOrder = Array.from(order);
                    newOrder.splice(oldIndex, 1);
                    newOrder.splice(newIndex, 0, active.id as string);

                    // update column order state
                    table.setColumnOrder(newOrder);
                  }
                }
              }}
            >
              <Table>
                {!loading && data.length > 0 && defaultColumnsApplied && (
                  <TableHeader>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableHead key={header.id}>
                              {header.isPlaceholder ? null : (
                                <DataTableColumnHeader
                                  id={header.id}
                                  column={header.column}
                                  table={table}
                                  className={cn(
                                    'border border-dashed border-transparent',
                                    dragOverId === header.id
                                      ? 'bg-gray-200 border-secondary'
                                      : ''
                                  )}
                                >
                                  {flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                  )}
                                </DataTableColumnHeader>
                              )}
                            </TableHead>
                          );
                        })}
                      </TableRow>
                    ))}
                  </TableHeader>
                )}

                <TableBody>
                  {(loading && data.length === 0) || !defaultColumnsApplied ? (
                    <TableRow className="hover:bg-transparent">
                      <TableCell colSpan={columns.length} className="h-56">
                        <LoadingIndicator
                          size="lg"
                          variant="page"
                          className="m-auto"
                        />
                      </TableCell>
                    </TableRow>
                  ) : table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow
                        key={row.id}
                        data-state={row.getIsSelected() && 'selected'}
                      >
                        {row.getVisibleCells().map((cell) => {
                          if (cell.column.id === 'actions') {
                            return (
                              <TableCell key={cell.id}>
                                <DataTableRowActions
                                  row={cell.row}
                                  table={table}
                                />
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell key={cell.id}>
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </TableCell>
                            );
                          }
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow className="hover:bg-transparent">
                      <TableCell
                        colSpan={table.getVisibleFlatColumns().length}
                        className="p-8"
                      >
                        <div className="flex flex-col items-center justify-center gap-2">
                          <IconAlertTriangle
                            stroke={1}
                            className="w-12 h-12 text-primary"
                          />
                          <p className="text-base text-secondary">
                            {message ?? 'No results.'}
                          </p>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>

              <DragOverlay>
                <div className="px-2 py-1.5 text-sm font-bold text-black bg-gray-200 border border-dashed rounded-md border-secondary cursor-move">
                  {dragActiveId ? formatLabel(dragActiveId) : null}
                </div>
              </DragOverlay>
            </DndContext>
          </div>
          <div className="flex flex-col gap-3 px-4 mt-4 md:flex-row md:justify-between md:items-center md:gap-4">
            <DataTablePagination table={table} loading={!allLoaded} />
          </div>
        </div>
      </div>

      {showMap && (
        <div className="w-full h-[500px]">
          <StaffMap table={table} />
        </div>
      )}
    </div>
  );
}
