import { IconX } from '@tabler/icons-react';
import { Table } from '@tanstack/react-table';
import { Tooltip } from 'cfa-react-components';
import { StaffPerson } from '../../interfaces/StaffPerson';
import { formatLabel } from './columns';

interface DataTableFilterOverviewProps {
  globalFilter?: string;
  table: Table<StaffPerson>;
}

export function DataTableFilterOverview({
  globalFilter,
  table,
}: DataTableFilterOverviewProps) {
  const columns = table.getAllFlatColumns().filter((c) => c.getIsFiltered());

  if (!globalFilter && columns.length === 0) return null;

  return (
    <div className="flex flex-row flex-wrap gap-2">
      {globalFilter ? (
        <LabelledTag
          value={globalFilter}
          handleClick={() => table.resetGlobalFilter()}
        />
      ) : null}

      {columns.map((c) => (
        <LabelledTag
          key={c.id}
          label={formatLabel(c.id)}
          value={String(c.getFilterValue())}
          handleClick={() => c.setFilterValue(undefined)}
        />
      ))}
    </div>
  );
}

export function LabelledTag({
  label,
  value,
  handleClick,
}: {
  label?: string;
  value: string;
  handleClick: any;
}) {
  return (
    <div className="flex flex-row items-center gap-2 p-1 text-xs bg-gray-100 border border-gray-200 rounded-md">
      <span className="ml-1">
        {label ? `${label}: ` : null}
        <b>{value}</b>
      </span>
      <Tooltip content="Remove Filter">
        <button
          className="transition-colors rounded-md text-gray-800 hover:bg-gray-200 p-0.5"
          onClick={handleClick}
        >
          <IconX className="w-4 h-4 " />
        </button>
      </Tooltip>
    </div>
  );
}
