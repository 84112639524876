import { IconMinus, IconPlus } from '@tabler/icons-react';
import L, { ZoomOptions } from 'leaflet';
import 'leaflet.fullscreen';
import { useEffect, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useMap } from 'react-leaflet';

export function ZoomControls(props: ZoomOptions): null {
  const map = useMap();
  const ctrl = useRef(
    L.control.zoom({
      ...props,
      zoomInText: ReactDOMServer.renderToString(
        <div className="flex items-center justify-center w-full h-full text-secondary">
          <IconPlus className="w-4 h-4" />
        </div>
      ),
      zoomOutText: ReactDOMServer.renderToString(
        <div className="flex items-center justify-center w-full h-full text-secondary">
          <IconMinus className="w-4 h-4" />
        </div>
      ),
    })
  );

  useEffect(() => {
    const current = ctrl.current;
    current.addTo(map);

    return () => {
      current.remove();
    };
  });

  return null;
}
