import { IconTableOptions } from '@tabler/icons-react';
import { Table } from '@tanstack/react-table';
import {
  Button,
  IconButton,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Multiselect,
  Tooltip,
} from 'cfa-react-components';
import { useState } from 'react';
import { StaffPerson } from '../../interfaces/StaffPerson';

export function DataTableColumnVisibility({
  table,
  loading,
}: {
  table: Table<StaffPerson>;
  loading: boolean;
}) {
  const [show, setShow] = useState(false);

  let hideableColumns = table
    .getAllColumns()
    .filter((column) => column.getCanHide());

  function changeAllColumnVisibility(visibility: boolean): void {
    hideableColumns.forEach((column) => {
      column.toggleVisibility(visibility);
    });
  }

  return (
    <>
      <Tooltip content="Manage Columns">
        <IconButton onClick={() => setShow(true)} disabled={loading}>
          <IconTableOptions />
        </IconButton>
      </Tooltip>

      <Modal show={show} onClose={() => setShow(false)} size="md">
        <>
          <ModalHeader>Manage Columns</ModalHeader>
          <ModalBody>
            <p className="mb-4">
              Customize which columns are displayed on the data table.
            </p>

            <Multiselect
              value={hideableColumns.filter((column) => column.getIsVisible())}
              onChange={(columns) => {
                columns.forEach((column) => {
                  column.toggleVisibility(true);
                });

                hideableColumns
                  .filter(
                    (column) =>
                      !columns.map((col) => col.id).includes(column.id)
                  )
                  .forEach((column) => column.toggleVisibility(false));
              }}
              options={hideableColumns.filter(
                (column) => !column.getIsVisible()
              )}
              getOptionId={(column) => column.id}
              getOptionText={(column) =>
                column.columnDef.meta?.label ?? column.id
              }
              placeholder="Search for a column to display..."
              renderNoResults={() =>
                'No more columns available to select. All columns are already displayed.'
              }
              fullWidth
            />
          </ModalBody>

          <ModalFooter>
            {hideableColumns.filter((column) => column.getIsVisible()).length >
            0 ? (
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => changeAllColumnVisibility(false)}
              >
                Hide All Columns
              </Button>
            ) : (
              <Button
                color="secondary"
                variant="outlined"
                onClick={() => changeAllColumnVisibility(true)}
              >
                Show All Columns
              </Button>
            )}

            <Button onClick={() => setShow(false)} variant="filled">
              Save and Close
            </Button>
          </ModalFooter>
        </>
      </Modal>
    </>
  );
}
