import { IconLockExclamation, IconMapPinOff } from '@tabler/icons-react';
import {
  Divider,
  Modal,
  ModalBody,
  ModalHeader,
  Typography,
} from 'cfa-react-components';
import { Dispatch, SetStateAction } from 'react';

import { StaffPerson } from '../../interfaces/StaffPerson';
import { getInitials } from '../../lib/utils';
import { MapMarker } from '../StaffMap/StaffMapMarker';
import { Map } from '../ui/Map';
import { StaffAttributesTable } from './StaffAttributesTable';

export function StaffDetailModal({
  show,
  setShow,
  person,
}: {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  person: StaffPerson;
}) {
  return (
    <Modal show={show} onClose={() => setShow(false)} size="lg">
      <ModalHeader className="-mb-4 bg-gray-100">
        <div className="flex flex-row items-center gap-6 mb-6 -mt-8">
          {person.sensitive_photo_link ? (
            <img
              src={person.sensitive_photo_link}
              className="rounded-full shadow-lg h-[108px] w-[108px] object-none"
              alt="portrait"
            />
          ) : (
            <div className="flex items-center justify-center h-[108px] w-[108px] overflow-hidden bg-secondary rounded-full shadow-lg">
              <span className="text-2xl font-bold text-white">
                {getInitials(person.preferredName)}
              </span>
            </div>
          )}
          <div className="text-left">
            <Typography color="secondary" variant="subtitle2">
              Detailed Information
            </Typography>
            <Typography color="default" variant="h2">
              {person.preferredName}
            </Typography>
          </div>
        </div>
      </ModalHeader>

      <ModalBody className="py-10 text-left">
        <Typography color="secondary" variant="h3">
          General Information
        </Typography>
        <StaffAttributesTable
          person={person}
          attributes={Object.keys(person).filter(
            (attribute) => !attribute.includes('sensitive')
          )}
        />

        <div className="my-8">
          <Divider variant="fullLength"></Divider>
        </div>

        <Typography color="secondary" variant="h3">
          Sensitive Information
        </Typography>
        {person.sensitive_addressPrivacy.toLowerCase() === 'true' ? (
          <div className="flex flex-col items-center justify-center gap-2 px-6 py-12 mt-2 text-center border rounded-md">
            <IconLockExclamation className="w-12 h-12 text-primary" />
            <p className="text-secondary">
              Address Privacy is enabled for {person.preferredName}.
            </p>
          </div>
        ) : (
          <>
            <StaffAttributesTable
              person={person}
              attributes={Object.keys(person).filter((attribute) =>
                attribute.includes('sensitive')
              )}
            />

            {person.sensitive_latitude && person.sensitive_longitude ? (
              <section className="w-full mt-8 h-96">
                <Map
                  center={[
                    Number(person.sensitive_latitude),
                    Number(person.sensitive_longitude),
                  ]}
                >
                  <MapMarker person={person} showDetail={false}></MapMarker>
                </Map>
              </section>
            ) : (
              <div className="flex flex-col items-center justify-center gap-2 px-6 py-12 mt-8 text-center border rounded-md">
                <IconMapPinOff className="w-12 h-12 text-primary" />
                <p className="text-secondary">
                  No address is available for {person.preferredName}.
                </p>
              </div>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
}
