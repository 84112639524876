import { Logo, Typography } from 'cfa-react-components/dist/cjs';

const Header = (): JSX.Element => {
  return (
    <div className="sticky top-0 z-10 py-2.5 bg-white shadow-md">
      <div className="container z-50 flex items-center gap-2">
        <Logo color="primary" logo="script" size="md" />
        <Typography
          variant="h2"
          as="span"
          className="flex-1 text-center text-secondary"
        >
          Staff Explorer
        </Typography>
      </div>
    </div>
  );
};

export default Header;
