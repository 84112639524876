import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function camelCaseToTitle(s: string) {
  const result = s.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
}

/**
 * Download the given contents as a CSV file with the specified filename.
 * Each row should be specified by a newline (e.g., `\n`) in the contents.
 *
 * @param contents the CSV contents to download
 * @param filename the desired name of the downloaded file
 */
export function downloadCsv(
  contents: string,
  filename: string = 'export'
): void {
  const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.setAttribute('href', url);
  link.setAttribute('download', filename + '.csv');
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

/**
 * Writes the given text to the system clipboard.
 *
 * @param text the text to copy
 * @returns a Promise resolved once the clipboard's contents have been updated
 */
export async function copyTextToClipboard(text: string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  }
}

/**
 * Get first initial of first word and first initial of last word provided.
 * Reference: https://github.com/cfacorp/cfa-react-components/blob/develop/src/Avatar/Avatar.tsx#L80
 *
 * @param name name to generate initials of
 * @returns initials of the given name
 */
export function getInitials(name: string) {
  const names = name.split(' ');
  let initials = names[0].substring(0, 1).toUpperCase();
  if (names.length > 1) {
    initials += names[names.length - 1].substring(0, 1).toUpperCase();
  } else {
    initials += name[1] ? name[1] : '';
  }
  return initials;
}

/**
 * Move the target string left or right in the given array of strings.
 *
 * @param arr initial array of strings
 * @param target string to move
 * @param direction direction to move the string
 * @returns updated array of strings
 */
export function moveString(
  arr: string[],
  target: string,
  direction: 'left' | 'right' | 'first' | 'last'
) {
  const index = arr.indexOf(target);

  if (index !== -1) {
    let adjacentIndex = direction === 'left' ? index - 1 : index + 1;

    if (direction === 'first') {
      adjacentIndex = 0;
    } else if (direction === 'last') {
      adjacentIndex = arr.length - 1;
    }

    if (adjacentIndex >= 0 && adjacentIndex < arr.length) {
      const temp = arr[adjacentIndex];
      arr[adjacentIndex] = arr[index];
      arr[index] = temp;
    }
  }

  return arr;
}
