import { Table } from '@tanstack/react-table';
import { useMemo } from 'react';
import { StaffPerson } from '../../interfaces/StaffPerson';
import { Map } from '../ui/Map';
import MarkerClusterGroup from '../ui/Map/MarkerCluster';
import { MapMarker } from './StaffMapMarker';

export function StaffMap({ table }: { table: Table<StaffPerson> }) {
  const filteredRows = table.getFilteredRowModel();

  const people = useMemo(() => {
    return filteredRows.flatRows
      .map(({ original }) => original)
      .filter((p) => p.sensitive_latitude && p.sensitive_longitude);
  }, [filteredRows]);

  return (
    <Map>
      <MarkerClusterGroup>
        {people.map((p) => (
          <MapMarker key={p.adId} person={p} />
        ))}
      </MarkerClusterGroup>
    </Map>
  );
}
